@import '@renderer-ui-library/mui/base/mixins.scss';
@import '@renderer-ui-library/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/colors.module.scss';

.wrapper {
  display: flex;
  flex-direction: column;

  @include mediaMin('md') {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $base * 4;
  }
}

.mediaWrapper {
  width: 100%;
}

.media {
  border: 1px solid $lightGray;

  @include mediaMax('sm') {
    margin-left: -$horizontalPageSpacingOnMobile;
    margin-right: -$horizontalPageSpacingOnMobile;
    border-left: none;
    border-right: none;
  }

  .noBorder {
    border: none;
  }

  & img {
    display: block !important;
    width: 100%;
  }

  & iframe {
    width: 100%;
    border: 1px solid $lightGray;
  }
}

.highlight {
  border: none;
}

.noBorder {
  border: none;
}

.contentWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: $base * 3;

  @include mediaMin('lg') {
    padding-top: 0;
  }
}

.imageRight {
  grid-auto-flow: dense;
  .contentWrapper {
    @include mediaMin('md') {
      grid-column: 1;
    }
  }

  .mediaWrapper {
    @include mediaMin('md') {
      grid-column: 2;
    }
  }
}
