@import '@renderer-ui-library/mui/base/constants.module.scss';
@import '@renderer-ui-library/mui/base/mixins.scss';

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $base * 6 0;
  min-height: 480px;

  @include mediaMax('md') {
    min-height: $base * 30;
  }
}

.machine {
  margin-top: $base * 6;

  @include mediaMax('md') {
    margin-top: $base * 2;
  }
}
